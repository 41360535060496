.hour-cell {
  text-align: center;
  font-size: 80%;
  border: solid 1px gray;
  width: 2em;
}

.legend-cell {
  text-align: center;
  font-size: 80%;
  border: solid 1px gray;
}

.booked-cell {
  background-color: lightcoral;
}

.booked-by-user-cell {
  background-color: lightgreen;
}